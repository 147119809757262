<template>
  <div class="page1">
    <div class="title">基本信息</div>
    <EForm :formColumns="formColumns" :rowSize="2" :optionsBtn="false" :actionBtn="true" :formData="form" ref="form"
           :searchFlag="false" :labelPosition="labelPosition" :labelWidth="labelWidth" :formRules="formRules"
           @getImgList="getImgList">
    </EForm>
<!--    <div class="title">证照信息</div>-->
<!--    <el-button type="primary" size="mini" class="mb20" @click="dialogVisible=true">添加证照</el-button>-->

    <!--    证照信息form-->
    <div v-if="licenceList.length>0">
      <!-- 营业执照-->
      <div class="mb10 fwb">营业执照</div>
      <div v-for="(item,index) in licenceList" :key="index">
        <div class="mb20">证照{{index+1}}信息</div>
        <EForm :formColumns="businessLicenseFormColumns" :rowSize="2" :optionsBtn="false" :actionBtn="true"
               :formData="item"
               ref="form2" :searchFlag="false" :labelPosition="labelPosition" labelWidth="150px"
               :formRules="formRules3"
               @getImgList="getImgList($event,index)"
               @changeRadio="changeRadio($event,index)"
               @beginDateChange="beginDateChange($event,index)"
               @endDateChange="endDateChange($event,index)"
        >
        </EForm>
      </div>
    </div>

    <div v-if="foodSafeLicenceList.length>0">
      <!--食品安全证-->
      <div class="mb10 fwb">食品安全许可证</div>
      <div v-for="(item,index) in foodSafeLicenceList" :key="index">
        <div class="mb20">证照{{index+1}}信息</div>
        <EForm :formColumns="foodLicenseFormColumns" :rowSize="2" :optionsBtn="false" :actionBtn="true"
               :formData="item"
               ref="form3" :searchFlag="false" :labelPosition="labelPosition" labelWidth="150px"
               :formRules="formRules3"
               @getImgList="getImgList2($event,index)">
        </EForm>
      </div>
    </div>
    <el-row>
      <el-button type="primary" @click="saveData('')" size="small">保存</el-button>
      <el-button type="primary" size="small" @click="saveData('sign')">保存并签合同</el-button>
      <el-button type="primary" @click="goBack" size="small">取消</el-button>
    </el-row>

    <!-- 选择证照名称弹窗 -->
    <EDialog :dialogVisible="dialogVisible" title="选择证照" @handleClose="cancelDialog" width="30%"
             @handleClick="onSummit(dialogForm.tenantCardPhotoType)"
    >
      <EForm :formColumns="dialogFormColumns"
             :rowSize="1" :optionsBtn="false"
             :actionBtn="false"
             :formData="dialogForm"
             ref="dialogForm" :searchFlag="false"
             :labelPosition="labelPosition"
             :labelWidth="labelWidth"
             :formRules="formRules2">
      </EForm>
    </EDialog>

  </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import infoList from '@/components/infoList'
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";
  import {item} from "../../assets/js/config";

  export default {
    name: 'Login',
    mixins: [otherMixin],
    data() {
      return {
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        formColumns: [
          {
            title: '商户名称',
            property: 'tenantName',
            type: 'input',
            placeholder: '商户名称',
            show: true
          },
          {
            title: '统一社会信用代码',
            property: 'socialCreditCode',
            type: 'input',
            placeholder: '请填写18位的统一社会信用代码',
            show: true
          },
          {
            title: '营业执照地址',
            property: 'businessLicenseAdress',
            type: 'input',
            placeholder: '如：XX省XX市XX县XX街道XX号',
            show: true
          },
          {
            title: '开户行及其账号',
            property: 'bankAccount',
            type: 'input',
            placeholder: '如：光大银行 XXXX XXXX XXXX XXXX',
            show: true
          },
          {
            title: '负责人姓名',
            property: 'signPerson',
            type: 'input',
            placeholder: '真实姓名',
            show: true
          },
          {
            title: '联系电话',
            property: 'phone',
            type: 'input',
            placeholder: '例如：18612148888',
            show: true
          },
          {
            title: '经营范围',
            property: 'businessScopeId',
            options: [],
            type: 'select',
            show: true,
            multiple:true

          },
          {
            title: '身份证号码',
            property: 'idCard',
            type: 'input',
            placeholder: '例如：430582199207280544',
            show: true
          },
          {
            title: '商户类型',
            property: 'tenantType',
            type: 'select',
            placeholder: '',
            show: true,
            options: [
              {
                label: '个体',
                value: 1
              },
              {
                label: '公司',
                value: 2
              },
              {
                label: '其他',
                value: 3
              },
            ]
          },
          {
            title: '负责人籍贯',
            property: 'birthPlace',
            type: 'input',
            placeholder: '籍贯',
            show: true
          },
          {
            title: '身份证照片',
            property: 'idCardPath',
            type: 'mixinUpload',
            show: true
          },
        ],
        dialogFormColumns: [
          {
            title: '证照名称',
            type: 'select',
            property: 'tenantCardPhotoType',
            placeholder: '',
            show: true,
            options: [
              {
                label: '营业执照',
                value: 1
              },
              {
                label: '食品经营许可证',
                value: 2
              }
            ]
          },
        ],
        //Business license营业执照
        businessLicenseFormColumns: [
          {
            title: '名称',
            type: 'input',
            property: 'operatorName',
            placeholder: '',
            show: true,
          },
          {
            title: '证照名称',
            type: 'select',
            property: 'tenantCardPhotoType',
            placeholder: '',
            show: true,
            disabled: true,
            options: [
              {
                label: '营业执照',
                value: 1
              },
              {
                label: '食品经营许可证',
                value: 2
              }
            ]
          },
          {
            title: '统一社会信用代码',
            type: 'input',
            property: 'idCard',
            placeholder: '',
            show: true,
          },
          {
            title: '住所',
            type: 'input',
            property: 'address',
            placeholder: '',
            show: true,
          },
          {
            title: ' 类别',
            type: 'radio',
            property: 'ttype',
            placeholder: '',
            show: true,
            options: [
              {
                label: '个体',
                value: '1'
              },
              {
                label: '公司',
                value: '2'
              },
            ]
          },
          {
            title: '登记机关',
            type: 'input',
            property: 'registerOffice',
            placeholder: '',
            show: true,
          },
          {
            title: ' 经营项目',
            type: 'input',
            property: 'businessScope',
            // options: [],
            placeholder: '',
            show: true,
          },
          {
            title: '签发日期',
            type: 'datePicker',
            property: 'issuerDate',
            show: true
          },
          {
            title: '注册资本',
            type: 'input',
            property: 'registeredCapital',
            placeholder: '',
            show: true,
          },
          {
            title: '经营期限',
            type: 'periods',
            property: 'validBeginDate',
            placeholder: '',
            show: true,
          },
          {
            title: '成立日期',
            type: 'datePicker',
            property: 'registerDate',
            show: true
          },
          {
            title: '  照片',
            type: 'mixinUpLoad',
            property: 'photoUrl',
            placeholder: '',
            show: true,
          },
        ],
        // Food safety license 食品安全许可证
        foodLicenseFormColumns: [
          {
            title: '经营者名称',
            type: 'input',
            property: 'operatorName',
            placeholder: '',
            show: true,
          },
          {
            title: '证照名称',
            type: 'select',
            property: 'tenantCardPhotoType',
            placeholder: '',
            show: true,
            disabled: true,
            options: [
              {
                label: '营业执照',
                value: 1
              },
              {
                label: '食品经营许可证',
                value: 2
              }
            ]
          },
          {
            title: '统一社会信用代码（身份证号码）',
            type: 'input',
            property: 'idCard',
            placeholder: '',
            show: true,
          },
          {
            title: '许可证编号',
            type: 'input',
            property: 'tenantCardPhotoCode',
            placeholder: '',
            show: true,
          },
          {
            title: '住所',
            type: 'input',
            property: 'address',
            placeholder: '',
            show: true,
          },
          {
            title: '日常监督管理机构',
            type: 'input',
            property: 'superviseOrganization',
            placeholder: '',
            show: true,
          },
          {
            title: '经营场所',
            type: 'input',
            property: 'premises',
            placeholder: '',
            show: true,
          },
          {
            title: '日常监督管理人员',
            type: 'input',
            property: 'supervisePerson',
            placeholder: '',
            show: true,
          },
          {
            title: '主体业态',
            type: 'input',
            property: 'mainWork',
            placeholder: '',
            show: true,
          },
          {
            title: '投诉举报电话',
            type: 'input',
            property: 'lineTelephone',
            placeholder: '',
            show: true,
          },
          {
            title: ' 经营范围',
            type: 'input',
            property: 'businessScope',
            // options: [],
            placeholder: '',
            show: true,

          },
          {
            title: '发证机关',
            type: 'input',
            property: 'registerOffice',
            placeholder: '',
            show: true,
          },
          {
            title: '签发日期',
            type: 'datePicker',
            property: 'issuerDate',
            show: true
          },
          {
            title: '签发人',
            type: 'input',
            property: 'issuerPerson',
            placeholder: '',
            show: true,
          },
          {
            title: '有效期至',
            type: 'datePicker',
            property: 'validEndDate',
            show: true
          },
          {
            title: '  照片',
            type: 'mixinUpLoad',
            property: 'photoUrl',
            placeholder: '',
            show: true,
          },
        ],
        licenceList: [],
        foodSafeLicenceList: [],
        // licenceList: [
        //   {
        //     baseList: [
        //       {
        //         label: '商户名称',
        //         property: 'title',
        //         type: 'input'
        //       },
        //       {
        //         label: '联系电话',
        //         property: 'mobile',
        //         type: 'input'
        //       },
        //     ],
        //     result: {
        //       title: '',
        //       mobile: ''
        //     }
        //   }
        // ],
        form: {
          tenantName: '',
          phone: '',
          signPerson: '',
          businessScopeId: [],
          idCard: '',
          tenantType: '',
          birthPlace: '',
          idCardPath: [],

        },
        // 营业执照
        businessInfoForm: {
          tenantBasicInformationId: '',
          tenantCardPhotoCode: '',
          businessScopeId: '',
          operatorName: '',
          address: '',
          registerOffice: '',
          registerDate: '',
          registeredCapital: '',
          tenantCardPhotoType: '',
          corporationPerson: '',
          idCard: '',
          ttype: '1',
          description: '',
          status: '1',
          photoUrl: '',
          validDateType: '1',
          validBeginDate: '',
          validEndDate: '',
        },
        // 食品安全证
        foodSafeInfoForm: {
          tenantBasicInformationId: '',
          tenantCardPhotoCode: '',
          businessScopeId: '',
          operatorName: '',
          address: '',
          superviseOrganization: '',
          supervisePerson: '',
          premises: '',
          mainWork: '',
          lineTelephone: '',
          registerOffice: '',
          issuerDate: '',
          issuerPerson: '',
          validEndDate: '',
          tenantCardPhotoType: '',
          corporationPerson: '',
          idCard: '',
          ttype: '1',
          description: '',
          status: '1',
          photoUrl: '',
        },
        dialogForm: {
          tenantCardPhotoType: ''
        },
        licenceListAll: [],//传给后台的两个数组合并成一个
        formRules: {
          tenantName: vxRule(true, '', "blur", "商户名称不能为空"),
          phone: [vxRule(true, '', "blur", "联系电话不能为空"),
            vxRule(true, val=>{
              return {result: val.toString().length==11, errMsg: "请输入正确的手机号"}})],
          signPerson: vxRule(true, '', "blur", "负责人姓名不能为空"),
          idCard: [vxRule(true, '', "blur", "身份证号码不能为空"),
            vxRule(true, val=>{
              return {result: val.toString().length==18, errMsg: "请输入18位身份证号"}})],
          businessScopeId: vxRule(true, '', "change", "经营范围不能为空"),
        },
        formRules2: {
          tenantCardPhotoType: vxRule(true, '', "change", "证照名称不能为空"),
        },
        formRules3: {
          operatorName: vxRule(true, '', "change", "经营者名称不能为空"),
          idCard: vxRule(true, '', "change", "统一社会信用代码不能为空"),
        },
        tenantBasicInformationId: '',//商户信息id
      }
    },
    watch: {},
    created() {
      this.businessscopeconfigLabel()
      this.cardbusinessscopeLabel()
    },
    components: {ETable, EButton, EForm, infoList, EDialog},
    beforeMount() {

    },
    methods: {
      beginDateChange(val, index) {
        this.licenceList[index].validBeginDate = val
      },
      endDateChange(val, index) {
        this.licenceList[index].validEndDate = val
      },
      getImgList(data, index) {
          this.form.idCardPath = data
      },
      getImgList2(data, index) {
        if (index || index == '0') {
          this.foodSafeLicenceList[index].photoUrl = data[0]
        }
        // this.form.idCardPath=data[0]
      },
      changeRadio(val, index) {
        this.licenceList[index].validDateType = val
      },
      addLicence() {
        this.licenceList.push({
          baseList: [
            {
              label: '商户名称',
              property: 'title',
              type: 'input'
            },
            {
              label: '联系电话',
              property: 'mobile',
              type: 'input'
            },
          ],
          result: {
            title: '',
            mobile: ''
          }
        })
      },
      cancelDialog() {
        this.dialogVisible = false
        this.dialogForm = {
          tenantCardPhotoType: '',
        }
        this.$refs.dialogForm.$refs.form.resetFields()
      },
      onSummit(tenantCardPhotoType) {
        this.$refs['dialogForm'].$refs['form'].validate((valid, object) => {
          if (valid) {
            this.dialogVisible = false
            if (tenantCardPhotoType == 1) {
              // 营业执照
              this.licenceList.push({
                businessScopeId: this.businessInfoForm.businessScopeId,
                corporationPerson: this.businessInfoForm.corporationPerson,
                description: this.businessInfoForm.description,
                operatorName: this.businessInfoForm.operatorName,
                address: this.businessInfoForm.address,
                idCard: this.businessInfoForm.idCard,
                photoUrl: this.businessInfoForm.photoUrl,
                status: this.businessInfoForm.status,
                tenantBasicInformationId: this.businessInfoForm.tenantBasicInformationId,
                tenantCardPhotoCode: this.businessInfoForm.tenantCardPhotoCode,
                tenantCardPhotoType: tenantCardPhotoType,
                ttype: this.businessInfoForm.ttype,
                registerDate: this.businessInfoForm.registerDate,
                registerOffice: this.businessInfoForm.registerOffice,
                registeredCapital: this.businessInfoForm.registeredCapital,
                validDateType: this.businessInfoForm.validDateType,
                validBeginDate: this.businessInfoForm.validBeginDate,
                validEndDate: this.businessInfoForm.validEndDate,
              })
            } else {
              // 食品安全许可证
              this.foodSafeLicenceList.push({
                businessScopeId: this.foodSafeInfoForm.businessScopeId,
                corporationPerson: this.foodSafeInfoForm.corporationPerson,
                description: this.foodSafeInfoForm.description,
                operatorName: this.businessInfoForm.operatorName,
                address: this.businessInfoForm.address,
                idCard: this.foodSafeInfoForm.idCard,
                photoUrl: this.foodSafeInfoForm.photoUrl,
                status: this.foodSafeInfoForm.status,
                superviseOrganization: this.foodSafeInfoForm.superviseOrganization,
                supervisePerson: this.foodSafeInfoForm.supervisePerson,
                premises: this.foodSafeInfoForm.premises,
                mainWork: this.foodSafeInfoForm.mainWork,
                lineTelephone: this.foodSafeInfoForm.lineTelephone,
                registerOffice: this.foodSafeInfoForm.registerOffice,
                issuerDate: this.foodSafeInfoForm.issuerDate,
                issuerPerson: this.foodSafeInfoForm.issuerPerson,
                tenantBasicInformationId: this.foodSafeInfoForm.tenantBasicInformationId,
                tenantCardPhotoCode: this.foodSafeInfoForm.tenantCardPhotoCode,
                tenantCardPhotoType: tenantCardPhotoType,
                ttype: this.foodSafeInfoForm.ttype,
                validEndDate: this.foodSafeInfoForm.validEndDate,
              })
            }

            this.cancelDialog()
          } else {
            return false;
          }
        })
      },
      saveData(flag) {
        let status = false
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          if (valid) {
            status = true
          } else {
            return false;
          }
        })
        let status1 = false
        if(this.licenceList.length>0){
          for (let i = 0; i < this.licenceList.length; i++) {
            this.$refs['form2'][i].$refs['form'].validate((valid) => {
              if (valid) {
                status1 = true
              } else {
                return false;
              }
            });
          }
        }else{
          status1=true
        }

        let status2 = false
        if(this.foodSafeLicenceList.length>0){
          for (let i = 0; i < this.foodSafeLicenceList.length; i++) {
            this.$refs['form3'][i].$refs['form'].validate((valid) => {
              if (valid) {
                status2 = true
              } else {
                return false;
              }
            });
          }
        }else{
          status2=true
        }

        if (status && status1 && status2) {
          this.getBasicInfoSubmit(flag)
        }
      },
      //新增商户
      async getBasicInfoSubmit(flag) {
        this.setDisabled(true)
        // this.form.businessScopeId=this.form.businessScopeId.toString()
        // this.form.idCardPath=this.form.idCardPath.toString()
       let form=JSON.parse(JSON.stringify(this.form))
        form.businessScopeId=form.businessScopeId.toString()
        form.idCardPath=form.idCardPath.toString()
        let res = await Http.getBasicInfoSubmit(form)
        if (res.code == 200) {
          this.setDisabled(false)
          if (this.licenceList.length == 0 && this.foodSafeLicenceList.length == 0) {
            this.form.idCardPath=[]
            //没有证照信息直接返回
            this.$message.success(res.msg);


          } else {
            //新增了证照信息
            let id = res.data.tenantBasicInformationId
            this.businessInfoForm.tenantBasicInformationId = id //商户id
            this.foodSafeInfoForm.tenantBasicInformationId = id //商户id
            // 将商户id赋给营业执照
            this.licenceList.forEach(item => {
              item.tenantBasicInformationId = id
            })
            // 将商户id赋给食品经营许可证
            this.foodSafeLicenceList.forEach(item => {
              item.tenantBasicInformationId = id
            })
            if (id) {
              //添加证照
              this.getPhotoSubmit(flag)
            }
          }
          if (flag) {//保存并且签合同
            this.$router.push({
              name: 'SignContract',
              query: {id: res.data.tenantBasicInformationId}
            })
          }else{
            this.$router.push({name: 'ShopBasicInfo'})
          }
        }
      },

      //新增证照
      async getPhotoSubmit(flag) {
        //将两个证照数组push进一个数组传给后台
        this.licenceList.forEach(item => {
          if (item.validDateType == 2) {
            item.validEndDate = ''
          }
          this.licenceListAll.push(item)
        })
        this.foodSafeLicenceList.forEach(item => {
          this.licenceListAll.push(item)
        })
        let res = await Http.cmtenantcardphotoSaveList(this.licenceListAll)
        if (res.code == 200) {
          //没有证照信息直接返回
          this.$message.success(res.msg);
          if (flag) {//保存并且签合同
            this.$router.push({
              name: 'SignContract',
              query: {id: res.data.tenantBasicInformationId}
            })
          }else{
            this.$router.push({name: 'ShopBasicInfo'})
          }
        }
      },
      //商户经营范围下拉
      async businessscopeconfigLabel() {
        let res = await Http.businessscopeconfigLabel({
          status: 1
        })
        if (res.code == 200) {
          this.formColumns[6].options = res.data
        }
      },
      //证照经营范围下拉
      async cardbusinessscopeLabel() {
        let res = await Http.cardbusinessscopeLabel({
          status: 1
        })
        if (res.code == 200) {
          this.businessLicenseFormColumns[6].options = res.data
          this.foodLicenseFormColumns[10].options = res.data
        }
      },
    }
  }
</script>

<style lang="scss">
  .title {
    font-weight: bold;
    padding-left: 10px;
    border-left: 2px solid #4d68ff;
    margin-bottom: 20px;
  }

  .small-title {
    margin-left: 10px;
    margin-top: 20px;
  }

  .mb20 {
    margin-bottom: 20px;
  }
</style>
